




































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseActionButtonWidget from '@/components/action-buttons/VueBaseActionButtonWidget';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { Getter } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import Speaker from '@/models/graphql/Speaker';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';

@Component({
  components: {
    ButtonComponent,
    FontAwesomeComponent,
  },
})
export default class ActionButtonLinkUserSpeaker extends VueBaseActionButtonWidget {
  @Prop({ required: true })
  protected readonly actionResult!: CommunityUser | Speaker;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  private linkedUid: string | undefined;

  private get text(): string {
    return (this.entityType === this.EntityTypeEnum.USER)
      ? `${this.$t('actions.speaker-profile')}`
      : `${this.$t('actions.user-profile')}`;
  }

  private get isDisplayed(): boolean {
    return !!((this.actionType === this.ActionButtonType.LINK_PROFILE_SPEAKER
            && (this.entityType === this.EntityTypeEnum.USER
                || this.entityType === this.EntityTypeEnum.SPEAKER))
        && this.linkedUid && this.featureByKey(FeatureKeys.COMMUNITY_SPEAKER_HUB_FEATURE)
        && this.featureByKey(FeatureKeys.COMMUNITY_SPEAKER_HUB_FEATURE).enabled);
  }

  created(): void {
    if (this.entityType === this.EntityTypeEnum.USER) {
      const user = this.actionResult as CommunityUser;
      if (user && user.speakers) {
        this.linkedUid = user.speakers[0]?.uid;
      }
    } else {
      this.linkedUid = (this.actionResult as Speaker).user?.uid;
    }
    this.buttonsDisplay();
  }

  @Watch('reRender')
  private buttonsDisplay(): void {
    this.commit('updateButtons', {
      index: this.index,
      visible: this.isDisplayed,
    });
  }

  private handleBaseUrl(): void {
    if (this.entityType === this.EntityTypeEnum.USER) {
      this.$router.push({
        name: 'speaker-detail',
        params: { speakerId: `${this.linkedUid}` },
      });
    } else {
      this.$router.push({
        name: 'member-detail',
        params: { memberId: `${this.linkedUid}` },
      });
    }
  }
}
